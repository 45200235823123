import React, { useRef } from "react";

export const HotDocFrame = () => {
    const ref = useRef();

    return (
        <iframe
            id="hotdoc"
            title="Book Appiontment"
            src="https://www.hotdoc.com.au/medical-centres/cranbourne-VIC-3977/broad-oak-medical-dental/doctors"
            width="100%"
            ref={ref}
            frameBorder="0"
            data-hotdoc-widget="iframe"
            className=""
            style={{
                width: "90%",
                margin: "0 auto",
                // overflow: "visible",
                minHeight: "800px",
            }}
            onLoad={() => {
                // const obj = ReactDOM.findDOMNode(ref.current);
                // setIFrameHeight(
                // 	obj.contentWindow.document.body.scrollHeight + "px"
                // );
                // setIFrameHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
            }}
        ></iframe>
    );
};
