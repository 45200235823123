import React from "react";

// Components
import { HotDocFrame } from "../components/HotDocFrame";

const BookAppointment = () => {
    return (
        <>
            <div className="iframe py-4">
                <HotDocFrame />
            </div>
        </>
    );
};

export default BookAppointment;
