import React, { useRef } from "react";

export const EAppointmentFrame = () => {
    const ref = useRef();

    return (
        <iframe
            id="hotdoc"
            title="Book Appiontment"
            src="https://www.centaurportal.com/d4w/org-2204/extended_search?sourceRef=https:%2F%2Fwww.centaurportal.com%2Fd4w%2Forg-2204%2FlandingAdmin%3FsourceID%3Dnull&location=2422&randomNumber=51613d7de10e8ca9b60ec4b3a48cbcaa7db97f32435a71e9a70b358b92a3a063&shortVer=false&su=3&countryCode=04&disableeForms=true"
            width="100%"
            ref={ref}
            frameBorder="0"
            data-hotdoc-widget="iframe"
            className=""
            style={{
                width: "90%",
                margin: "0 auto",
                // overflow: "visible",
                minHeight: "800px",
            }}
            onLoad={() => {
                // const obj = ReactDOM.findDOMNode(ref.current);
                // setIFrameHeight(
                // 	obj.contentWindow.document.body.scrollHeight + "px"
                // );
                // setIFrameHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
            }}
        ></iframe>
    );
};
